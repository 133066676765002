
import mediaHelper from "@libraryHelpers/media.js";
export default {
	name: "CoreBlockVideo",
	props: {
		settings: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			jwplayerLibraryUrl:
				"https://content.jwplatform.com/libraries/ljXVSIQY.js",
			htmlVideoId: "jwplayer-script-video",
		};
	},
	computed: {
		isExpired() {
			const itemCheckFunction = (item) =>
				item.type === this.settings.type;
			return this.$store.getters["library/isExpired"](
				this.settings.contentLibraryItems,
				itemCheckFunction
			);
		},
		src() {
			const mediaSrc = this.settings?.src;
			const src =
				mediaSrc && typeof mediaSrc === "object"
					? mediaSrc.src
					: mediaSrc;
			return src;
		},
		isFile() {
			return this.src?.includes("uploads");
		},
		isEMoneyUrl() {
			if (this.src && mediaHelper.isEMoney(this.src)) return true;
			return false;
		},
		playerId() {
			if (this.isEMoneyUrl) {
				return this.checkIfHasPlayerId(this.src);
			}
			return "";
		},
	},
	async mounted() {
		if (this.isEMoneyUrl) {
			await this.loadScript(this.jwplayerLibraryUrl);
			this.loadPlayer();
		}
	},
	methods: {
		checkIfHasPlayerId(url) {
			return mediaHelper.checkIfHasPlayerId(url);
		},
		loadScript(src, callback) {
			return new Promise((resolve) => {
				const scriptExists = document.querySelector(
					`script[src="${src}"]`
				);
				if (scriptExists) {
					resolve(true);
					return;
				}

				let script = document.createElement("script");
				script.src = src;
				script.onload = () => {
					if (callback) {
						callback();
					}
					resolve(true);
				};
				document.body.appendChild(script);
			});
		},
		loadPlayer() {
			if (!this.src) {
				return;
			}

			const existingPlayerScript = document.getElementById(
				this.htmlVideoId
			);
			if (existingPlayerScript) {
				this.removeLoadedVideoScript();
			}

			const script = document.createElement("script");
			script.id = this.htmlVideoId;
			script.src = this.src;
			document.head.appendChild(script);
		},
		removeLoadedVideoScript() {
			const existingVideoScript = document.getElementById(
				this.htmlVideoId
			);
			if (!existingVideoScript) {
				return;
			}
			existingVideoScript.parentNode.removeChild(existingVideoScript);
		},
	},
};
